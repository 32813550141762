<template>
  <plan-card>
    <slot name="scale">
      <forecast-scale :tiers="tiers" :forecast="forecast" :client="client" :distributions="distributions" :isEos="isEos"/>
    </slot>
    <slot name="company">
<!--      <forecast-company-details v-if="!client.hide_client_results" :performance="client.performance.current" class="company-details" />-->
    </slot>
  </plan-card>
</template>

<script>
import PlanCard from "@/components/plans/global/PlanCard.vue";
import ForecastScale from "@/components/plans/short-term/scale/ForecastScale.vue";
// import ForecastCompanyDetails from "@/components/plans/short-term/scale/ForecastCompanyDetails.vue";

export default {
  name: "PlanScale",

  components: {
    PlanCard,
    ForecastScale,
    // ForecastCompanyDetails,
  },

  props: {
    tiers: {
      type: Object,
      required: true,
    },
    forecast: {
      type: Object,
      required: true,
    },
    client: {
      type: Object,
      required: true,
    },
    distributions: {
      type: Array,
      required: false,
    },
    isEos: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.company-details {
  border-top: thin solid $color-card-border;
}
</style>